import { useRoutes } from 'react-router-dom';
import { CssBaseline, Grid, ThemeProvider, StyledEngineProvider } from '@mui/material';
import { styled } from '@mui/material/styles';
import LazyLoadRoute from 'components/common/LazyLoadRoute';
import theme from './theme';
import routes from './routes';
import useAuth from './hooks/Auth0';
import './index.css';
import { useSelector, useDispatch } from 'react-redux';
import { setLoggedBrands } from 'store/layerListenerSlice';
import { setCredentials } from '@carto/react-redux';
import { setDefaultCredentials } from '@deck.gl/carto';
import { initialState } from 'store/initialStateSlice';

import CircularProgressWithIcon from 'components/custom/CircularProgressWithIcon';
import { useEffect } from 'react';
import { setLoadingApp } from 'store/appSlice'
import Maintanance from 'components/views/Maintanance';

let credentials = { ...initialState.credentials }

const GridApp = styled(Grid)(() => ({
  flex: '1 1 auto',
  flexDirection: 'column',
  overflow: 'hidden',
  position: 'relative',
  width: '100%',
  height: '100%'
}));

export default function App() {
  const { loadingApp, loadingMap } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const storedUser = JSON.parse(localStorage.getItem('loggedBrands'));
  const {credentials} = useSelector(state => state.carto);

  useEffect(() => {
    dispatch(setLoadingApp(true));
  }, [])

  const maintananceMode = process.env.REACT_APP_MAINTENANCE_MODE;

  if(storedUser && !credentials.accessToken){
    const brandAccessString = process.env.REACT_APP_BRAND_ACCESS;
    const jsonString = atob(brandAccessString);
    const brandAccessObj = JSON.parse(jsonString);
    const access = storedUser.admin ? brandAccessObj.find(o => storedUser.id === o.id) : brandAccessObj.find(o => storedUser.brands.some(s => o.brands.some(brand => brand.id === s.id)) && o.id === storedUser.id);
    if(access){
      const stateUpdate = { brands: access.brands, id: access.id, admin: access.admin, oohAccess: !!access.oohAccess, inMarketAccess: !!access.inMarketAccess};
      dispatch(setLoggedBrands(stateUpdate));
      setDefaultCredentials({...credentials, accessToken: access.accessToken})
      dispatch(setCredentials({ ...credentials, accessToken: access.accessToken })); 
    }
  }

  const routing = useRoutes(routes);
  useAuth();

  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GridApp container>
          {maintananceMode === true ? (
            <Maintanance />
          )
        :
          (
            <>
            <LazyLoadRoute>{routing}</LazyLoadRoute>
            {loadingApp && (
              <div style={overlayStyle}>
                <CircularProgressWithIcon className='loader' showLoadingText={true}/>
              </div>
            )}
          </>
          )}
          
        </GridApp>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
