import deepmerge from 'deepmerge';
import { createTheme } from '@mui/material';
import { cartoThemeOptions } from '@carto/react-ui';
import { DateRangePicker } from 'react-date-range';

const customTheme = {
    zIndex:{
        Popper: 1300
    },
    components: {
        MuiTypography: {
          styleOverrides: {
            root: {
              fontSize: '11px',
              color: '#162945',
              fontWeight: 'bold',
            },
          },
        },
      },
};

//cartoThemeOptions.typography.subtitle1.fontFamily = 'Times New Roman'
cartoThemeOptions.palette.primary.main = '#84CEEF';
cartoThemeOptions.palette.secondary.main = '#84CEEF';
cartoThemeOptions.palette.secondary.dark = '#1783b5'
cartoThemeOptions.palette.secondary.light = '#bbe4f6'


const theme = createTheme(deepmerge(cartoThemeOptions, customTheme));

export default theme;
