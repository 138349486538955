import { POSITRON } from '@carto/react-basemaps';
import { API_VERSIONS } from '@deck.gl/carto';

const clientId = process.env.REACT_APP_CLIENT_ID;

export const initialState = {
  viewState: {
    latitude: -30.5595,
    longitude: 22.9375,
    zoom: 6,
    pitch: 10,
    bearing: 0,
    tilt: 0,
    heading: 0,
    streetViewControl: false,
    isFractionalZoomEnabled: true,
    dragRotate: true,
    altitude: 1.5,
    //viewMode: '3D',
    mapTypeControlOptions: {
      mapTypeIds: ['roadmap', 'terrain'],
    },
  },
  basemap: POSITRON,
  credentials: {
    apiVersion: API_VERSIONS.V3,
    apiBaseUrl: 'https://gcp-europe-west1.api.carto.com',
    //accessToken: 'eyJhbGciOiJIUzI1NiJ9.eyJhIjoiYWNfbGg5NzI0dDUiLCJqdGkiOiJkMmJlODJkNCJ9.7GqFkHh1Q-GSjDMB9WWLYzvCNTw_BTs-fqCYbC1gvfs'
  },
  googleApiKey: 'AIzaSyAuMStOcOJ2cf2GttPrNkmaX85qNCJxtMo', // only required when using a Google Basemap
  googleMapId: 'AIzaSyAuMStOcOJ2cf2GttPrNkmaX85qNCJxtMo', // only required when using a Google Custom Basemap
  accountsUrl: 'http://app.carto.com/',
  oauth: {
    namespace: 'http://app.carto.com/',
    domain: 'auth.carto.com',
    clientId: clientId , // type here your application clientId
    organizationId: '', // organizationId is required for SSO
    scopes: [
      'read:current_user',
      'update:current_user',
      'read:connections',
      'write:connections',
      'read:maps',
      'write:maps',
      'read:account',
      'admin:account',
    ],
    audience: 'carto-cloud-native-api',
    authorizeEndPoint: 'https://carto.com/oauth2/authorize', // only valid if keeping https://localhost:3000/oauthCallback
  },
};
