
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from 'react-redux';

function FillingCircularProgress(props) {
  const { loadingPercentage } = useSelector(state => state.layerData);


  return (
      <CircularProgress variant="determinate" value={loadingPercentage} {...props} />
  );
}

export default FillingCircularProgress;