import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'app',
  initialState: {
    error: null,
    bottomSheetOpen: false,
    inmarket: true,
    brandlocation: false,
    isSidebarOpen: true,
    loadingApp: true,
    loadingMap: true,
    map2Layers: [],
    map2ViewState: {
      latitude: -30.5595,
      longitude: 22.9375,
      zoom: 6,
      pitch: 10,
      bearing: 0,
      tilt: 0,
      heading: 0,
      streetViewControl: false,
      isFractionalZoomEnabled: true,
      dragRotate: true,
      altitude: 1.5,
      mapTypeControlOptions: {
          mapTypeIds: [
              "roadmap",
              "terrain"
          ]
      },
      width: 427,
      height: 905,
      maxZoom: 20,
      minZoom: 0,
      maxPitch: 60,
      minPitch: 0,
      normalize: true,
      position: [
          0,
          0,
          0
      ]
    }
  },
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    setBottomSheetOpen: (state, action) => {
      state.bottomSheetOpen = action.payload;
    },
    setBrandIdsQuery: (state, action) => {
      state.brandIdsQuery = action.payload;
    },
    setTotalBrandLocation: (state, action) => {
      state.totalBrandLocation = action.payload;
    },
    clearTotalBrandLocation: (state) => {
      state.totalBrandLocation = 0;
    },
    setDates: (state, action) => {
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
    },
    toggleLayerVisibility: (state, action) => {
      const { layer, visibility } = action.payload;
      state.layers[layer] = visibility;
    },
    toggleSidebar: (state) => {
      state.isSidebarOpen = !state.isSidebarOpen;
    },
    setMap2ViewState: (state, action) => {
      state.map2ViewState = action.payload;
    },
    setMap2Size: (state, action) => {
      state.map2ViewState.width = action.payload.width;
      state.map2ViewState.height = action.payload.height;
    },
    setMap2Layers: (state, action) => {
      state.map2Layers = action.payload;
    },
    setLoadingMap: (state, action) => {
      state.loadingMap = action.payload;
    },
    setLoadingApp: (state, action) => {
      state.loadingApp = action.payload;
    },
  },
});

export default slice.reducer;
export const { 
  setBrandIdsQuery,
  setTotalBrandLocation, 
  clearTotalBrandLocation, 
  setDates, 
  toggleSidebar,
  setMap2ViewState,
  setMap2Size,
  setMap2Layers,
  setLoadingMap,
  setLoadingApp } = slice.actions;
export const setError = (payload) => ({ type: 'app/setError', payload });
export const setBottomSheetOpen = (payload) => ({
  type: 'app/setBottomSheetOpen',
  payload,
});
