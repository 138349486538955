import { Grid, Typography} from '@mui/material';
import { styled } from '@mui/material/styles';
import VicinityLoginCenterImg from 'assets/img/vicinity-logo-smaller.png';
import ConstructionIcon from '@mui/icons-material/Construction';

const GridContainer = styled(Grid)(({ theme }) => ({
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    height: '100%',
    backgroundImage: `url(${require('assets/img/vic-login-img.jpg')})`, // Use 'url()' function to set background image
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(8, 28, 0),
    },
  
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4, 5, 0),
    },
  }));

const GridContent = styled(Grid)(({ theme }) => ({
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  background: 'transparent',
  color: 'white',
}));

const WelcomeMessage = styled(Typography)({
  fontSize: '2rem',
  marginBottom: '1rem',
});

const TopImage = styled('img')({
  marginBottom: '1.5rem',
});



export default function Maintanance() {
  return (
    <GridContainer>
        <GridContent item container>
            <TopImage src={VicinityLoginCenterImg} alt="Vicinity Logo" />
            <WelcomeMessage variant="h1" color="inherit" sx={{textAlign: 'center'}}>
                We are currently doing maintenance on the Visualiser. The site will be back up and running soon
            </WelcomeMessage>
            <ConstructionIcon sx={{fontSize: '40px'}}/>
        </GridContent>
    </GridContainer>
  );
}
