import { useSelector } from 'react-redux';


function LoadingPercentageText(props) {
  const { loadingPercentage, isLoadingLayers } = useSelector(state => state.layerData);

  return (
    <p style={{textAlign: 'center'}}>Loading... {isLoadingLayers && (`${loadingPercentage}%`)}</p>
  );
}

export default LoadingPercentageText;