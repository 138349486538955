import { createSlice } from '@reduxjs/toolkit';

const layerListenerSlice = createSlice({
  name: 'layerListener',
  initialState: {
    unexposedTimeSeriesDate: "",
    exposedTimeSeriesDate: "",
    poiSelections: [],
    webAnalyticsP1Selections: [],
    webAnalyticsPlacementSelections: [],
    loggedBrands: [],
    visibleBrands: [],
    refreshLayersTrigger: false
  },
  reducers: {
    setUnexposedTimeSeriesDate: (state, action) => {
      state.unexposedTimeSeriesDate = action.payload;
    },
    setExposedTimeSeriesDate: (state, action) => {
      state.exposedTimeSeriesDate = action.payload
    },
    setPOISelections: (state,action) => {
      state.poiSelections = action.payload;
    },
    setWebAnalyticsP1Selections: (state,action) => {
      state.webAnalyticsP1Selections = action.payload;
    },
    setWebAnalyticsPlacementSelections: (state,action) => {
      state.webAnalyticsPlacementSelections = action.payload;
    },
    setLoggedBrands: (state, action) => {
      state.loggedBrands = action.payload
    },
    setVisibleBrands: (state, action) => {
      state.visibleBrands = action.payload;
    },
    setRefreshLayersTrigger: (state, action) => {
      state.refreshLayersTrigger = action.payload;
    }
  }
});

export default layerListenerSlice.reducer;
export const { 
  setUnexposedTimeSeriesDate,
  setExposedTimeSeriesDate,
  setPOISelections,
  setWebAnalyticsP1Selections,
  setWebAnalyticsPlacementSelections,
  setLoggedBrands,
  setVisibleBrands,
  setRefreshLayersTrigger
} = layerListenerSlice.actions;
