import { createSlice } from '@reduxjs/toolkit';

const LayerDataSlice = createSlice({
  name: 'layerData',
  initialState: {
    webAnalytics: [],
    timeSeriesGeom: [],
    loadingPercentage: 0,
    isLoadingLayers: false
  },
  reducers: {
    setWebAnalytics: (state, action) => {
      state.webAnalytics = action.payload;
    },
    setTimeSeriesGeom: (state,action) => {
      state.timeSeriesGeom = action.payload
    },
    setLoadingPercentage: (state,action) => {
      state.loadingPercentage = action.payload;
    },
    setIsLoadingLayers: (state, action) => {
      state.isLoadingLayers = action.payload;
    }
  }
});

export default LayerDataSlice.reducer;
export const { setWebAnalytics, setTimeSeriesGeom, setLoadingPercentage, setIsLoadingLayers } = LayerDataSlice.actions;
